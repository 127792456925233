import React from 'react';
import { METADATA_FUNCTION_URL, PRESIGNED_FUNCTION_URL } from '../Constants';

export function useAppService() {
  async function getFileMetadata(file: string) {
    console.log('getting file metadata');
    let response = await fetch(METADATA_FUNCTION_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        file: file,
      }),
    });
    if (!response.ok) {
      throw new Error('Could not get file metadata.');
    }
    let data = await response.json();
    return data;
  }

  async function gretPresignedUrl(name: string, email: string, file: string) {
    console.log('getting presigned url');
    let response = await fetch(PRESIGNED_FUNCTION_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: name,
        email: email,
        file: file,
      }),
    });
    if (!response.ok) {
      throw new Error('Could not get presigned URL.');
    }
    let data = await response.json();
    return data;
  }

  return {
    getFileMetadata,
    gretPresignedUrl,
  };
}
