import { useEffect, useState } from 'react';
import {
  TextInput,
  Paper,
  Title,
  Text,
  Container,
  Button,
  Alert,
  Center,
  Box,
  Grid,
  Loader,
  Progress,
} from '@mantine/core';
import { useForm, isNotEmpty, isEmail } from '@mantine/form';
import { IconAlertCircle, IconDownload } from '@tabler/icons-react';
import { useAppService } from '../services/AppService';
import { IconLogin } from '@tabler/icons-react';

enum FormStatus {
  READY,
  SUBMITTING,
  ERROR,
}

interface FormValues {
  fullName: string;
  email: string;
}

interface FileMetadata {
  contentType: string;
  size: number;
}

export function Home() {
  const appService = useAppService();
  const [formStatus, setFormStatus] = useState<FormStatus>(FormStatus.READY);
  const [fileMetadata, setFileMetadata] = useState<FileMetadata | null>(null)
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  
  const searchParams = new URLSearchParams(window.location.search);
  
  const form = useForm<FormValues>({
    validateInputOnBlur: true,
    initialValues: {
      fullName: '',
      email: '',
    },
    validate: {
      fullName: isNotEmpty('Full name is required'),
      email: isEmail('E-mail address is not valid'),
    },
  });

  const file = searchParams.get("f");
  
  async function onSubmit(values: any) {
    if (file === null) {
      return;
    }
    setFormStatus(FormStatus.SUBMITTING);
    try {
      let presign = await appService.gretPresignedUrl(form.values.fullName, form.values.email, file);
      setDownloadUrl(presign.url);
    } catch (e: any) {
      console.error(e);
      setFormStatus(FormStatus.ERROR);
    }
  };

  function formatBytes(bytes: number, decimals: number = 2) {
    if (!+bytes) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  function startDownload() {
    if (downloadUrl === null) {
      return;
    }
    setDownloading(true);
    setTimeout(() => {
      let link = document.createElement("a");
      link.href = downloadUrl;
      link.click();
      setTimeout(() => {
        setDownloading(false);
      }, 10000)
    }, 1000);
  }

  useEffect(() => {
    (async function () {
      if (file !== null && file !== '') {
        try {
          let metadata = await appService.getFileMetadata(file);
          setFileMetadata(metadata);
        } catch (e) {
          setError(true);
        }
      } else {
        setError(true);
      }
    })();
  }, [])

  return (
    <Container size={620} my={40}>
      <Title
        align="center"
        sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
      >
        Download File
      </Title>
      <Paper withBorder shadow="md" radius="md" my="xl">
        {fileMetadata === null && !error ? (
          <Center>
            <Box pt={20} pb={15}>
              <Loader /> 
            </Box>
          </Center>
        ) : (
          <>
            {error ? (
              <Text size="sm" color="red" p={20} align="center">Unable to locate the requested file. Please try again later.</Text>
            ) : (
              <Grid gutter="xs" my="sm" justify="center">
                <Grid.Col span={5} sx={{textAlign: 'right'}}>
                  <Text size="sm">Name:</Text>
                </Grid.Col>
                <Grid.Col span={7}>
                  <Text color="dimmed" size="sm">{file}</Text>
                </Grid.Col>
                <Grid.Col span={5} sx={{textAlign: 'right'}}>
                  <Text size="sm">Content Type:</Text>
                </Grid.Col>
                <Grid.Col span={7}>
                  <Text color="dimmed" size="sm">{fileMetadata !== null ? fileMetadata.contentType : '?'}</Text>
                </Grid.Col>
                <Grid.Col span={5} sx={{textAlign: 'right'}}>
                  <Text size="sm">Size:</Text>
                </Grid.Col>
                <Grid.Col span={7}>
                  <Text color="dimmed" size="sm">{fileMetadata !== null ? formatBytes(fileMetadata.size) : '?'}</Text>
                </Grid.Col>
              </Grid>
            )}
          </>
        )}
      </Paper>
      {downloadUrl !== null ? (
        <>
          <Text color="dimmed" size="sm" align="center" mt={5}>
            {downloading ? 'Your download will start momentarily...' : 'Thank you. Please click the button below to start your download.'}
          </Text>
          <Center>
            {downloading ? (
              <Loader mt={20} />
            ) : (
              <Button onClick={startDownload} disabled={downloading} leftIcon={<IconDownload size="1rem" />} radius="md" my={10} size="xl">Download File</Button>
            )}
          </Center>
        </>
      ) : (
        <>
          <Text color="dimmed" size="sm" align="center" mt={5}>
            Please submit the following information to download the requested file.
          </Text>
          <Paper withBorder shadow="md" p={30} mt={10} radius="md">
            {formStatus === FormStatus.ERROR && (
              <Alert icon={<IconAlertCircle size="1rem" />} title="Sign in failed" color="red" mb="1.5rem">
                The username or password specified could not be authenticated. Please check your credentials and try again.
              </Alert>
            )}
            <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
              <TextInput
                withAsterisk
                label="Full Name"
                placeholder="Enter full name"
                {...form.getInputProps('fullName')}
              />
              <TextInput
                withAsterisk
                label="E-mail Address"
                placeholder="Enter e-mail address"
                mt="md"
                {...form.getInputProps('email')}
              />
              <Button type="submit" leftIcon={<IconLogin size="1rem" />} disabled={error || !form.isValid()} loading={formStatus === FormStatus.SUBMITTING} fullWidth mt="xl">
                {formStatus === FormStatus.SUBMITTING ? 'Submitting...' : 'Submit'}
              </Button>
            </form>
          </Paper>
        </>
      )}
    </Container>
  );
}