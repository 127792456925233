import { MantineProvider } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useAppService } from './services/AppService';
import { Home } from './pages/Home';
import { Loading } from './pages/Loading';

export default function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const appService = useAppService();

  useEffect(() => {
    (async function () {
      setTimeout(() => setLoading(false), 750);
    })();
  }, []);

  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        colorScheme: 'dark',
      }}>
      {loading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <Home />
        </>
      )}
    </MantineProvider>
  );
}